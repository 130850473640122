import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import AboutUsHero from "../AboutUs/AboutUsHero"
import SiteSelectorsNav from "../shared/Navs/AboutUsNav";
import PageTitle from "../shared/PageTitle/PageTitle";
import "./MeetingMinutes.scss";

const pageTitle = 'EDA Board of Directors Meeting Minutes';

const MeetingMinutes = (data) => {

    return(
        <div>
            <AboutUsHero/>
            <Container>
                <SiteSelectorsNav/>
                <PageTitle title={pageTitle} />
                <Col>
                    <Row>
                        <div class="minutes-col-wrapper">
                            <div class="minutes-col">
                                <p><strong>2024</strong></p>
                                <ul>
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.us-east-1.amazonaws.com/meeting-minutes/october+2024+minutes+final+signed.pdf" target="blank;">
                                            October 17, 2024
                                        </a>
                                    </li> 
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/meeting-minutes/sept+2024+minutes+final+signed.pdf" target="blank;">
                                            September 19, 2024
                                        </a>
                                    </li> 
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/meeting-minutes/August+2024+Board+Meeting+Minutes+Final.pdf" target="blank;">
                                            August 15, 2024
                                        </a>
                                    </li> 
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/meeting-minutes/june+26th+2024+special+mtg+minutes+signed.pdf" target="blank;">
                                            June 26, 2024
                                        </a>
                                    </li> 
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/meeting-minutes/june+20th+2024+regular+mtg+minutes+signed.pdf" target="blank;">
                                            June 20, 2024
                                        </a>
                                    </li> 
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/meeting-minutes/april+2024+minutes.pdf" target="blank;">
                                            April 18, 2024
                                        </a>
                                    </li> 
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/meeting-minutes/march+minutes+2024+signed.pdf" target="blank;">
                                            March 21, 2024
                                        </a>
                                    </li> 
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/meeting-minutes/jan+minutes+2024+signed.pdf" target="blank;">
                                            January 18, 2024
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="minutes-col">
                                <p><strong>2023</strong></p>
                                <ul>
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/meeting-minutes/dec+14+2023+minutes+final.pdf" target="blank;">
                                            December 14, 2023
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/meeting-minutes/nov+16+2023+minutes+final.pdf" target="blank;">
                                            November 16, 2023
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/meeting-minutes/september+2023+minutes+signed.pdf" target="blank;">
                                            September 21, 2023
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/meeting-minutes/august+30+2023+special+meeting+minutes.pdf" target="blank;">
                                            August 30, 2023
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/meeting-minutes/august+17+2023+special+meeting+minutes.pdf" target="blank;">
                                            August 17, 2023
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/about-us/july+minutes+final+2023.docx" target="blank;">
                                            July 20, 2023
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/meeting-minutes/june+23+minutes+signed.pdf" target="blank;">
                                            June 23, 2023
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/meeting-minutes/may+2023+board+minutes+final.pdf" target="blank;">
                                            May 18, 2023
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/171223-april%20minutes%202023%20final%20signed.pdf" target="blank;">
                                            April 20, 2023
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/141215-Minutes%2002%2015%2023.pdf" target="blank;">
                                            February 16, 2023
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/about-us/Meeting+Minutes/jan+19+2023+minutes+(1).pdf" target="blank;">
                                            January 19, 2023
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="minutes-col">
                                <p><strong>2022</strong></p>
                                <ul>
                                <li>
                                    <a href="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/161640-dec%202023%20mins%20signed.pdf" target="blank;">
                                        December 15, 2022
                                    </a>
                                </li>
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/161616-nov%202023%20mins%20signed.pdf" target="blank;">
                                            November 17, 2022
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/161525-october%20minutes%202022%20signed.pdf" target="blank;">
                                            October 20, 2022
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/161442-sept%202022%20minutes%20signed.pdf" target="blank;">
                                            September 28, 2022
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/141136-Minutes%20Sept%202022.pdf" target="blank;">
                                            September 28, 2022 (Finance)
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/about-us/Meeting+Minutes/Aug+2022+Special+Mtg+Minutes+Signed.pdf" target="blank;">
                                            August 30, 2022
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/about-us/Meeting+Minutes/Finance+July+Minutes+Final+2022.pdf" target="blank;">
                                            July 21, 2022 (Finance)
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/about-us/Meeting+Minutes/July+2022+Reg+Mtg+Signed+Minutes.pdf" target="blank;">
                                            July 21, 2022
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://henrico-eda-site-assets.s3.amazonaws.com/about-us/Meeting+Minutes/061622+Minutes.pdf" target="blank;">
                                            July 16, 2022
                                        </a>
                                    </li>                                   
                                </ul>
                            </div>
                        </div>
                    </Row>
                </Col>
            </Container>
        </div>
    )
}

export default MeetingMinutes;
