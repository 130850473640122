import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import "./CorporateRegionalHeadquarters.scss";
import NewsInsights from '../shared/NewsInsights/NewsInsights';
import LogosImage from './images/headquarter-logosupdated1024.jpg';
import TargetSectorsHero from './TargetSectorsHero';
import TargetSectorsNav from '../shared/Navs/TargetSectorsNav';
import PageTitle from '../shared/PageTitle/PageTitle';
import HenricoTomorrowBanner from './images/HEN24010_Tomorrow_WebBanner_1200x600.png';
import "./TargetSectors.scss";

const pageTitle = 'Corporate and Regional Headquarters';

// const paragraphStyles = {
//     fontWeight: 600
// };

const CorporateRegionalHeadquarters = () => {
    return (
        <div>
            <TargetSectorsHero />
            <Container>
                <TargetSectorsNav />
                <PageTitle title={pageTitle} />
                <Row>
                    <Col lg={12} sm={12} md={12} xl={12}>
                    <h2 className="page-header"><i>Henrico Tomorrow</i></h2>
                    <p>Henrico is the perfect home for corporate and regional headquarters. With our robust pool of qualified workers, affordable cost of living, and high quality of life, establishing a headquarters in Henrico is a strategic move for the future of your business.</p>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <img alt="logos" src={HenricoTomorrowBanner} className="tomorrow-banner-img" margin-top="0"/>
                    <Col lg={12} sm={12} md={12} xl={12} className="henrico-tomorrow-banner">
                        <h2 class="banner-title1">Transforming Today's Office Into</h2>
                        <h2 class="banner-title2">The Workplace of Tomorrow</h2>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} sm={12} md={12} xl={12}>
                    <h2 className="paragraph-header--blue">A Comprehensive Effort to Elevate Office Space in Henrico</h2>
                    <p>Here at the Home of the Extra Mile, we always have our eyes on the future. That’s why we developed <i>Henrico Tomorrow</i>, a comprehensive package for corporate services. This robust collection of initiatives is the culmination of a yearslong effort to elevate office space in Henrico to premier status. </p>
                    <p><i>Henrico Tomorrow</i> was developed by the Henrico Office Task Force, a cohort of local industry experts in both private and public sectors, who convened throughout 2023-2024 to formulate best practices for advancing Henrico’s office stock. Leverage one or more of <i>Henrico Tomorrow’s</i> generous incentives to create the dynamic, collaborative workspace your contemporary team needs to flourish.</p><br></br>
                    <h2 className="paragraph-header--blue">Greater Henrico Technology Zone</h2>
                    <p>Grow your company in a workspace uniquely designed for your success. Plug into our enhanced Technology Zone, which includes <i>every</i> office building in Henrico, and boot up your business with building and permit fee waivers, custom incentives, and more. Collaborate directly with the Henrico EDA to tailor financial solutions specific to your business needs. For more information, contact Ashley Kubat at <a href="mailto:Ashley@henrico.com">Ashley@henrico.com</a>.</p>
                    <br></br>
                    <h2 className="paragraph-header--blue">Office Reimagining Grant</h2>
                    <p><a href="https://henrico-eda-site-assets.s3.us-east-1.amazonaws.com/target-sectors/office+reimagining+grant+program+flyer+v+2.pdf" target="_blank;">Grants</a> of up to $25,000 are available for architectural services performed by a licensed architectural firm* related to redesign or reuse of an existing office building. For more information, contact Wendy Miller at <a href="mailto:Wendy@henrico.com">Wendy@henrico.com</a>.</p>
                    <div className="logo-row">
                        <p>*Our partners at <a href="https://www.baskervill.com/" target="_blank">Baskervill</a> are immediately available to assist. </p>
                        <a href="https://www.baskervill.com/" target="_blank"><img src="https://henrico-eda-site-assets.s3.us-east-1.amazonaws.com/target-sectors/Picture21.jpg" alt="Baskervill Logo" width="100px"/></a>
                    </div>
                    <br></br>
                    <h2 className="paragraph-header--blue">Office Renovation Incentive Program</h2>
                    <p>This <a href="https://henrico-eda-site-assets.s3.us-east-1.amazonaws.com/target-sectors/Office+Renovation+Incentive+Program+flyer+v+2.pdf" target="_blank;">program</a> offers reimbursement grants up to $500,000 for the renovation of qualifying office properties.  For more information, contact Wendy Miller at <a href="mailto:Wendy@henrico.com">Wendy@henrico.com</a>.</p>
                    <br></br>
                    <h2 className="paragraph-header--blue">Business, Professional, and Occupational License (BPOL) Exemption</h2>
                    <p>In 2024, Henrico’s Board of Supervisors approved a Business, Professional, and Occupational License (BPOL) <a href="https://www.henrico.com/news/press-releases-and-announcements/2024-5-29/bpol" target="_blank">tax threshold increase</a> to $1,000,000, essentially exempting 15,800 Henrico businesses from the tax and reducing the tax burden of thousands more.</p>
                    <br></br>
                    <h2 className="paragraph-header--blue">Enhanced Reinvest: Commercial Investment Tax Abatement Program</h2>
                    <p>Effective January 1, 2025, business may receive a partial tax exemption for up to 15 years on the value of an office building. To learn more about the Reinvest program, visit <a href="https://henrico.gov/services/reinvest-tax-abatement-program-for-rehabilitated-structures/" target="_blank">https://henrico.gov/services/reinvest-tax-abatement-program-for-rehabilitated-structures/</a></p>
                    <br></br>
                    <h2 className="paragraph-header--blue">Henrico Global Business Gateway</h2>
                    <p>Henrico’s office also has a global reach through the <a href="https://www.henrico.com/target-sectors/international-companies" target="_blank">Henrico Global Business Gateway</a>, which debuted in September 2024. This initiative provides established international businesses a launching point and a customized level of support to expand into the US market. For more information, contact Andrew Larsen at <a href="mailto:Andrew@henrico.com">Andrew@henrico.com</a>.</p>

                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <img className="logo-space" alt="logos" src={LogosImage} width="100%" />
                    <p>Seven Fortune 1000 firms are headquartered in Henrico: Altria Group, Genworth Financial, Markel Corporation, ASGN, Arko Corp, Brink’s and Owens & Minor. Henrico is also home to national, international, regional and divisional headquarters for a diverse set of companies such as Allianz Global Assistance, Fareva, Patient First, Kroger and McKesson Medical-Surgical. Discover what's attracting these industry leaders to our locality. Download our convenient <a href="https://henrico-eda-site-assets.s3.us-east-1.amazonaws.com/target-sectors/Corporate+Services+One-Pager.11.14.24.pdf" target="_blank">Corporate Services brochure</a> now.</p>
                </Row>
                <NewsInsights />
            </Container>
        </div>
    );
    };

export default CorporateRegionalHeadquarters;
