import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { RemoteContent } from "../shared/Cms";
import AboutUsHero from "./AboutUsHero";
import SiteSelectorsNav from "../shared/Navs/AboutUsNav";
import PageTitle from "../shared/PageTitle/PageTitle";
import "./OurTeam.scss";

const teamMembers = [
  {
    image: "image2",
    name: "name2",
    title: "title2",
    expertise: "expertise2",
    copy: "copy2"
  },
  {
    image: "image8",
    name: "name8",
    title: "title8",
    expertise: "expertise8",
    copy: "copy8"
  },  
  {
    image: "image10",
    name: "name10",
    title: "title10",
    expertise: "expertise10",
    copy: "copy10"
  },
  {
    image: "image9",
    name: "name9",
    title: "title9",
    expertise: "expertise9",
    copy: "copy9"
  },
  {
    image: "image7",
    name: "name7",
    title: "title7",
    expertise: "expertise7",
    copy: "copy7"
  }, 
  {
    image: "image4",
    name: "name4",
    title: "title4",
    expertise: "expertise4",
    copy: "copy4"
  },
  // {
  //   image: "image5",
  //   name: "name5",
  //   title: "title5",
  //   expertise: "expertise5",
  //   copy: "copy5"
  // },
  {
    image: "image1",
    name: "name1",
    title: "title1",
    expertise: "expertise1",
    copy: "copy1"
  },
  {
    image: "image3",
    name: "name3",
    title: "title3",
    expertise: "expertise3",
    copy: "copy3"
  },  
  {
    image: "image6",
    name: "name6",
    title: "title6",
    expertise: "expertise6",
    copy: "copy6"
  }, 
  {
    image: "image11",
    name: "name11",
    title: "title11",
    expertise: "expertise11",
    copy: "copy11"
  }
];

const AboutUs = (data) => {
  const { domain, section, page } = {
    domain: "henrico",
    section: "about-us",
    page: "Our Team",
  };

  return (
    <div>
      <AboutUsHero />
      <Container>
        <SiteSelectorsNav />
        <PageTitle
          title={
            <RemoteContent
              domain={domain}
              section={section}
              page={page}
              field="title"
            />
          }
        />
        <Row className="row-intro">
          <Col>
            <p className="paragraph-header--blue">Our Team Is Your Team</p>
            <p>
              Whether your business is new or established, domestic or
              international, the Henrico EDA is your central point of contact
              for development in Henrico. Our skilled staff will help you
              navigate the complexities of site selection – from initial
              research to completed deal. And for businesses already located in
              Henrico, we’re always on call to help you succeed in any way we
              can. 
            </p>
              {/* Contact us at <a href="tel:1.804.501.7654">804.501.7654</a>{" "}
              or fill out{" "}
              <a
                href="/about-us/contact-us"
                target="_blank"
                rel="noopener noreferrer"
              >
                this form.
              </a> */}

            
            <br />

            {/* <img src="https://henrico-eda-site-assets.s3.amazonaws.com/portal-uploads/19418-Henrico+EDA+Headshots+%C2%A9+Caroline+Martin+Photography-26.jpg" width="1000" class="center"/>
            <br /> */}          

            To learn more about areas of opportunity to develop community-wide
            success and discover the Henrico EDA's vision for continued growth,
            please read our{" "}
            <a
              href="https://henrico-eda-site-assets.s3.amazonaws.com/about-us/Ady+Advantage-Henrico+County+EDA+Strategic+Plan+FINAL+0422+2020.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              2020 Economic Development Strategic Plan
            </a>
            .<br />
            <br />
          </Col>
        </Row>
        <Row className="row-members">
          <Col>
            {teamMembers.map((member) => {
              return (
                <Row className="row-individual-member">
                  <Col lg={3} sm={5} md={4} xl={3}>
                    <RemoteContent
                      domain={domain}
                      section={section}
                      page={page}
                      field={member.image}
                    />
                  </Col>
                  <Col lg={9} sm={7} md={8} xl={9} className="pull-right">
                    <RemoteContent
                      domain={domain}
                      section={section}
                      page={page}
                      field={member.name}
                      className="member-name paragraph-header--blue"
                    />
                    <RemoteContent
                      domain={domain}
                      section={section}
                      page={page}
                      field={member.title}
                      className="member-title copy-blue"
                    />
                    {/* <RemoteContent
                      domain={domain}
                      section={section}
                      page={page}
                      field={member.expertise}
                      className="member-expertise-title"
                    /> */}
                    <RemoteContent
                      domain={domain}
                      section={section}
                      page={page}
                      field={member.copy}
                    />
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
        <div>
          <Row>
            <Col lg={2} sm={2} md={2} xl={3}></Col>
            <Col lg={8} sm={8} md={8} xl={5}>
              <h5 className="text-center">
                <span style={{ color: "#04368B", fontWeight: "800" }}>
                  {" "}
                  Board of Directors
                </span>{" "}
              </h5>
            </Col>
            <Col lg={2} sm={2} md={2} xl={3}></Col>
          </Row>
          <Row>
            <Col lg={2} sm={2} md={2} xl={3}></Col>
            <Col lg={8} sm={8} md={8} xl={5} className="text-center">
              <RemoteContent
                domain={domain}
                section={section}
                page={page}
                field={"boardOfDirectors"}
              />
            </Col>
            <Col lg={2} sm={2} md={2} xl={3}></Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col lg={2} sm={2} md={2} xl={3}></Col>
            <Col lg={8} sm={8} md={8} xl={5}>
              <h5 className="text-center">
                <span style={{ color: "#04368B", fontWeight: "800" }}>
                  {" "}
                  Board Meetings
                </span>{" "}
              </h5>
            </Col>
            <Col lg={2} sm={2} md={2} xl={3}></Col>
          </Row>
          <Row>
            <Col lg={2} sm={2} md={2} xl={3}></Col>

            <Col lg={8} sm={8} md={8} xl={5} className="text-center">
              {/* <RemoteContent domain={domain} section={section} page={page} field={"boardMeetings"} /> */}
              <p>A Special Meeting on Wednesday December 11, 2024 @ 5:00 p.m. in the Board Room of the Henrico EDA located at 4300 Parham Rd., Henrico VA<br></br><br></br>The December Board Meeting is cancelled.  A Special Meeting will be held starting at 6:00 p.m. at Hondo's located at 4120 Cox Rd.<br></br><br></br>Board meetings are held on the third Thursday of each month at 6:30 p.m. in the Board Room of the Henrico EDA Office, located at 4300 E. Parham Rd., Henrico, VA. </p>
            </Col>
            <Col lg={2} sm={2} md={2} xl={3}></Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col lg={2} sm={2} md={2} xl={3}></Col>
            <Col lg={8} sm={8} md={8} xl={5} className="text-center">
              <h5 className="text-center" style={{ fontWeight: "900" }}>
                {" "}
                Committee Meetings{" "}
              </h5>
              <RemoteContent
                domain={domain}
                section={section}
                page={page}
                field={"committeeMeetings"}
              />
            </Col>
            <Col lg={2} sm={2} md={2} xl={3}></Col>
          </Row>
          <Row>
            <Col lg={2} sm={2} md={2} xl={2}></Col>
            <Col lg={8} sm={8} md={8} xl={8}>
              {/* <p className="text-center">
                Committee Meetings are held in the Board Room of the EDA office
                located at 4300 E. Parham Rd. Henrico, VA 23228 unless otherwise
                noted.
              </p> */}
              {/* <p className="text-center">
                Notice is hereby given that the Regular Meeting of the Economic Development Authority of Henrico County, Virginia, scheduled to be held on <i>Thursday, December 21, 2023 at 6:30 p.m. at 4300 East Parham Road, Henrico, VA 23228,</i> has been cancelled.
              </p>
              <p className="text-center">
                Notice is hereby given that the Economic Development Authority of Henrico County, Virginia will hold a special meeting of the Board of Directors on <u><i>Thursday, December 14, 2023 at 6:00 p.m. at Cooper's Hawk, 11792 West Broad Street, Henrico, VA 23233</i></u>.
              </p> */}
            </Col>
            <Col lg={2} sm={2} md={2} xl={2}></Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default AboutUs;
